import React, { Component } from 'react';

export const InfowinHome = (props) => <>
  <section
    id="goto267301"
    className="section-base mainBox background-setting"
    style={{
      backgroundImage: `url(${(obj.background &&
        obj.background.find(item => item.background1) &&
        obj.background.find(item => item.background1).background1) ||
        'https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/025af993647d8156116d7795d6f69df4_double.jpg'})`,
    }}
  >
    <div className="ContentsBox style-one">
      <div className="imgBox">
        <img
          decoding="async"
          src={`${(obj.photo &&
            obj.photo.find(item => item.photo1) &&
            obj.photo.find(item => item.photo1).photo1) ||
            'https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/cd2e0535441964dda609195a6112cf95_double.png'}`}
          alt=""
        />
      </div>
      <div className="aBox" />
    </div>
  </section>
  <section
    className="section-base galleryBox"
    style={{
      backgroundImage: `url(${(obj.background &&
        obj.background.find(item => item.background2) &&
        obj.background.find(item => item.background2).background2) ||
        ''})`,
    }}
  >
    <div className="ContentsBox default clearfix">
      <div className="aBox">
        <h3 id="newtitle1" className="main_title">
          PHOTOGRAPHY
        </h3>
        <p id="newtitle2" className="sub_title">
          Jack studio
          成立於2015年，一直以來以數位藝術與設計作為不變的創作主軸。
          <br />
          最專業的人像/商品/美食/品牌形象攝影團隊。
        </p>
      </div>
      <div className="aBox">
        <div className="photo_list clearfix">
          {albumArr.length ? (
            albumArr.map(item => (
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() => this.handleOpen(item.img1File.url)}
                >
                  <img
                    src={(item.img1File2 && item.img1File2.url) || ''}
                    width="260px"
                    height="260px"
                    alt=""
                  />
                  <figcaption className="j-n-caption">
                    <span>{item.description}</span>
                  </figcaption>
                </figure>
              </div>
            ))
          ) : (
            <>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/5ed97cfac8f9f9ba7fbee0bd3aaa674f_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/5ed97cfac8f9f9ba7fbee0bd3aaa674f_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/b4ad1464fd4f3535c6ed2532bb02dc24_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/b4ad1464fd4f3535c6ed2532bb02dc24_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/fcb3b1a3b7dd8ddc96e76b2358e7e399_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/fcb3b1a3b7dd8ddc96e76b2358e7e399_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/8018d290a985eab3d6f47c630a9295a2_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/8018d290a985eab3d6f47c630a9295a2_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/ff2463a15c739851f10407a2e677afed_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/ff2463a15c739851f10407a2e677afed_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/7dc07c0aca3527f8a0c4649419b7afea_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/7dc07c0aca3527f8a0c4649419b7afea_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/efb4efac5e7c9d7b8f69075ea948951c_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/efb4efac5e7c9d7b8f69075ea948951c_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/f89cf56a5b5391146b3a17dab2227893_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/f89cf56a5b5391146b3a17dab2227893_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/e4bc1dd4c904d2faa66beced9982d5a9_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/e4bc1dd4c904d2faa66beced9982d5a9_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/63228355096376b2d297a31823816ce9_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/63228355096376b2d297a31823816ce9_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/c29b985900c02b6ee4fe10b12ef8fa05_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/c29b985900c02b6ee4fe10b12ef8fa05_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
              <div className="grid_container">
                <figure
                  className="j-n-image photo"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/4a960ecc8acedc3aabb1eddb36e9f63e_double.jpg',
                    )
                  }
                >
                  <img
                    src="//img.holkee.com/site/theme/unknown/4a960ecc8acedc3aabb1eddb36e9f63e_double.jpg"
                    width="260"
                    height="260"
                    alt=""
                  />
                  <figcaption
                    className="j-n-caption"
                    style={{ display: 'none' }}
                  >
                    <span />
                  </figcaption>
                </figure>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  </section>
  <section
    className="section-base about_us_box"
    style={{
      backgroundImage: `url(${(obj.background &&
        obj.background.find(item => item.background3) &&
        obj.background.find(item => item.background3).background3) ||
        ''})`,
    }}
  >
    <div className="ContentsBox default clearfix">
      <div className="aBox fl">
        <div className="imgBox">
          <img
            src={`${(obj.photo &&
              obj.photo.find(item => item.photo2) &&
              obj.photo.find(item => item.photo2).photo2) ||
              'https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/e6961d713fc1b9069f9b814c2e0706ef_double.jpg'}`}
            alt=""
          />
        </div>
      </div>
      <div className="aBox fr">
        <h3 id="newtitle3" className="main_title">
          Jack studio
        </h3>
        <p
          id="newtitle4"
          className="sub_title"
          style={{ textAlign: 'center' }}
        >
          《 服務項目 》<br />
          <br />
          廣告人像攝影
          <br />
          <br />
          建築室內空間攝影
          <br />
          <br />
          藝術品攝影
          <br />
          <br />
          商業攝影
          <br />
          <br />
          企業形像攝影
          <br /> <br />
          婚禮紀錄
          <br />
          <br />
          微電影拍製
          <br />
          <br />
          全家福攝影
          <br />
          <br />
          工商活動攝影
          <br />
          <br />
          行銷影片拍製
        </p>
        <p className="sub_btn">
          <a
            className="ana-button-click"
            href="#goto267304"
            target="_top"
            data-vars-event-category="聯繫我們"
            data-vars-event-label="#goto267304"
          >
            聯繫我們
          </a>
        </p>
      </div>
    </div>
  </section>
  <section
    className="section-base about_us_box"
    style={{
      backgroundImage: `url(${(obj.background &&
        obj.background.find(item => item.background4) &&
        obj.background.find(item => item.background4).background4) ||
        ''})`,
    }}
  >
    <div className="ContentsBox style-two clearfix">
      <div className="aBox">
        <div className="imgBox">
          <img
            src={`${(obj.photo &&
              obj.photo.find(item => item.photo3) &&
              obj.photo.find(item => item.photo3).photo3) ||
              'https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/9b489bd3dc684aff2f1ead33f0892e09_double.png'}`}
            alt=""
          />
        </div>
      </div>
      <div className="aBox">
        <h3
          id="newtitle5"
          className="main_title"
          style={{
            textAlign: 'center',
            fontSize: '1.75rem',
            color: '#FFFFFF',
          }}
        />
        <p id="newtitle6" className="sub_title" />
      </div>
    </div>
  </section>
  <section
    className="section-base slidesBox"
    style={{
      backgroundImage: `url(${(obj.background &&
        obj.background.find(item => item.background5) &&
        obj.background.find(item => item.background5).background5) ||
        ''})`,
    }}
  >
    <div className="ContentsBox style-one">
      <div className="text-box">
        <h3 id="newtitle7" className="main_title">
          TEAM LEADER
        </h3>
        <p id="newtitle8" className="sub_title" />
      </div>
      <div className="slider-box i-amphtml-element i-amphtml-layout-fixed-height i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout">
        <div className="i-amphtml-carousel-content">
          <div className="i-amphtml-carousel-scroll">
            {teamArr.length ? (
              teamArr.map(item => (
                <div
                  className="content-box"
                  onClick={() => this.handleOpen(item.img1File.url)}
                >
                  <div className="j-n-image photo">
                    <img
                      src={
                        (item.img1File2 && item.img1File2.url) || ''
                      }
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="j-n-description description">
                      <p>{item.description}</p>
                    </div>
                    <div
                      className="j-n-button"
                      style={{ display: 'none' }}
                    >
                      <p>
                        <a className="btn" href="#" />
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div
                  className="content-box"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/723e15f48c280184067f61da38610596_double.jpg',
                    )
                  }
                >
                  <div className="j-n-image photo">
                    <img
                      src="//img.holkee.com/site/theme/unknown/723e15f48c280184067f61da38610596_double.jpg"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="j-n-description description">
                      <p>STEVE</p>
                    </div>
                    <div
                      className="j-n-button"
                      style={{ display: 'none' }}
                    >
                      <p>
                        <a className="btn" href="#" />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="content-box"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/c47bc2930cbcea7fe5c6568f9b825806_double.jpg',
                    )
                  }
                >
                  <div className="j-n-image photo">
                    <img
                      src="//img.holkee.com/site/theme/unknown/c47bc2930cbcea7fe5c6568f9b825806_double.jpg"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="j-n-description description">
                      <p>ANN</p>
                    </div>
                    <div
                      className="j-n-button"
                      style={{ display: 'none' }}
                    >
                      <p>
                        <a className="btn" href="#" />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="content-box"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/bf28990d883349fa6745e0ab63da1618_double.jpg',
                    )
                  }
                >
                  <div className="j-n-image photo">
                    <img
                      src="//img.holkee.com/site/theme/unknown/bf28990d883349fa6745e0ab63da1618_double.jpg"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="j-n-description description">
                      <p>JESSICA</p>
                    </div>
                    <div
                      className="j-n-button"
                      style={{ display: 'none' }}
                    >
                      <p>
                        <a className="btn" href="#" />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="content-box"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/e53ddbeb4079b54031619d6c0823a80a_double.jpg',
                    )
                  }
                >
                  <div className="j-n-image photo">
                    <img
                      src="//img.holkee.com/site/theme/unknown/e53ddbeb4079b54031619d6c0823a80a_double.jpg"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="j-n-description description">
                      <p>CECILIA</p>
                    </div>
                    <div
                      className="j-n-button"
                      style={{ display: 'none' }}
                    >
                      <p>
                        <a className="btn" href="#" />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="content-box"
                  onClick={() =>
                    this.handleOpen(
                      '//img.holkee.com/site/theme/unknown/b0aa967460b665c3262254e831176ffe_double.jpg',
                    )
                  }
                >
                  <div className="j-n-image photo">
                    <img
                      src="//img.holkee.com/site/theme/unknown/b0aa967460b665c3262254e831176ffe_double.jpg"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="j-n-description description">
                      <p>KIM HEECHUL</p>
                    </div>
                    <div
                      className="j-n-button"
                      style={{ display: 'none' }}
                    >
                      <p>
                        <a className="btn" href="#" />
                      </p>
                    </div>
                  </div>
                </div>
                {/* <li className="content-box" onClick={() => this.handleOpen("//img.holkee.com/site/theme/unknown/2686d4107829e98e7eecf92849916c85_double.jpg")}>
        <div className="j-n-image photo">
          <img src="//img.holkee.com/site/theme/unknown/2686d4107829e98e7eecf92849916c85_double.jpg" alt='' />
        </div>
        <div className="info">
          <div className="j-n-description description"><p>ABNER</p>
          </div>
          <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
          </div>
        </div>
      </li>
      <li className="content-box" onClick={() => this.handleOpen("//img.holkee.com/site/theme/unknown/c4f1b68dc36d22e1b2c78ad41f0e84a2_double.jpg")}>
        <div className="j-n-image photo">
          <img src="//img.holkee.com/site/theme/unknown/c4f1b68dc36d22e1b2c78ad41f0e84a2_double.jpg" alt='' />
        </div>
        <div className="info">
          <div className="j-n-description description"><p>HARDY</p>
          </div>
          <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
          </div>
        </div>
      </li> */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="section-base contact_us"
    style={{
      backgroundImage: `url(${(obj.background &&
        obj.background.find(item => item.background6) &&
        obj.background.find(item => item.background6).background6) ||
        ''})`,
    }}
  >
    <div className="ContentsBox default clearfix">
      <div className="aBox">
        <div id="mapBox" className="mapBox">
          {/* <iframe className='i-amphtml-element i-amphtml-layout-fill i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout' sandbox="allow-scripts allow-same-origin allow-popups" title='google' src={(obj.contact && obj.contact.newMap) || "https://www.google.com/maps/embed/v1/place?key=AIzaSyDXtBW26A1ECFElryP7Qar7EPx78d3ptV0&q=%E5%8F%B0%E5%8C%97%E5%B8%82%E4%BF%A1%E7%BE%A9%E8%B7%AF%E4%BA%94%E6%AE%B57%E8%99%9F"} width='100%' height='100%' /> */}
          {/* <iframe src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBOLwZNcRE9yn0-tythMMwX5OIkpEbpFWw&q=106台北市大安區舟山路237號(NTU Department of Life Science)&language=en`} width="600" height="450" frameBorder="0" style={{ border: '0' }} allowFullscreen /> */}
          {/* <iframe className='i-amphtml-element i-amphtml-layout-fill i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout' sandbox="allow-scripts allow-same-origin allow-popups" title='google' src="https://www.google.com.tw/maps/place/%E5%90%89%E9%87%8E%E7%83%A4%E8%82%89%E9%A3%AF/@24.9570741,121.2381035,17z/data=!3m1!4b1!4m5!3m4!1s0x34682215a0cd6cab:0x86f664e7fe277360!8m2!3d24.9570741!4d121.2402975?hl=zh-TW" width='100%' height='100%' /> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d465743.2933408488!2d120.9558744!3d24.2201031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346882845fc2e7ef%3A0xddd9fd85e0b0aa31!2z5aSq6a2v6Zaj5ZyL5a625YWs5ZyS!5e0!3m2!1szh-TW!2stw!4v1607410224077!5m2!1szh-TW!2stw"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: '0' }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
        </div>
      </div>
      <div className="aBox">
        <h3
          id="newtitle9"
          className="main_title"
          style={{ textAlign: 'center', fontSize: '1.75rem' }}
        >
          Jack studio
        </h3>
        <p
          id="newtitle10"
          className="sub_title"
          style={{ textAlign: 'center', fontSize: '0.9375rem' }}
        >
          欢迎各种拍摄与合作邀约
        </p>
        <ul className="info">
          <li>
            <p className="address">
              {(obj && obj.address) || '台北市信義路五段7號'}
            </p>
          </li>
          <li>
            <p className="phone">
              <a
                className="ana-button-click"
                href="tel:0908-000-1234"
                data-vars-event-category="contact"
                data-vars-event-label="0908-000-1234"
              >
                {(obj && obj.phone) || '0908-000-1234'}
              </a>
            </p>
          </li>
          <li>
            <p className="time">
              {(obj && obj.time) || '10:00 AM - 11:30 PM'}
            </p>
          </li>
          <li>
            <p className="email">
              <a
                className="ana-button-click"
                href="mailto:service@example.com"
                data-vars-event-category="contact"
                data-vars-event-label="service@example.com"
              >
                {(obj && obj.email) || 'service@example.com'}
              </a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section
    className="section-base follow_us background-setting"
    style={{
      backgroundImage: `url(${(obj.background &&
        obj.background.find(item => item.background7) &&
        obj.background.find(item => item.background7).background7) ||
        'https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/1ada06d12fb2fab39f3437a1b37d54f0_double.jpg'})`,
    }}
  >
    <div className="ContentsBox default">
      <h3
        id="newtitle11"
        className="main_title"
        style={{ textAlign: 'center', fontSize: '1.75rem' }}
      >
        FOLLOW US
      </h3>
      <p
        id="newtitle12"
        className="sub_title"
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '0.9375rem',
        }}
      >
        關注我們的 Facebook 粉絲頁或追蹤我們的
        Instagram，以關注我們的最新動態！
      </p>
      <ul className="follow_us_icon">
        {obj.social && obj.social.length ? (
          obj.social.map(item => (
            <li>
              <a
                className={item.className}
                href={item.url}
                style={{ backgroundColor: 'rgb(0, 0, 0)' }}
                target="_blank"
              >
                <i className={`fab ${item.icon}`} />
              </a>
            </li>
          ))
        ) : (
          <>
            <li>
              <a
                className="ana-social-click"
                style={{ backgroundColor: '#000000' }}
                href="https://www.holkee.com"
                target="_blank"
                data-vars-event-category="social"
                data-vars-event-label="Facebook"
              >
                <i className="fab fa-facebook-square" />
              </a>
            </li>
            <li>
              <a
                className="ana-social-click"
                style={{ backgroundColor: '#000000' }}
                href="https://www.holkee.com"
                target="_blank"
                data-vars-event-category="social"
                data-vars-event-label="Instagram"
              >
                <i className="fab fa-instagram" />
              </a>
            </li>
            <li>
              <a
                className="ana-social-click"
                style={{ backgroundColor: '#000000' }}
                href="https://line.me/R/ti/p/@bca1493j"
                target="_blank"
                data-vars-event-category="social"
                data-vars-event-label="LINE"
              >
                <i className="fab fa-line" />
              </a>
            </li>
            <li>
              <a
                className="ana-social-click"
                style={{ backgroundColor: '#000000' }}
                href="https://twitter.com/holkeego"
                target="_blank"
                data-vars-event-category="social"
                data-vars-event-label="Twitter"
              >
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a
                className="ana-social-click"
                style={{ backgroundColor: '#000000' }}
                href="https://www.youtube.com/channel/UCWdDQAD5syDC1nYlhMOVeMA/"
                target="_blank"
                data-vars-event-category="social"
                data-vars-event-label="YouTube"
              >
                <i className="fab fa-youtube" />
              </a>
            </li>
          </>
        )}
      </ul>
    </div>
  </section>
  <footer className="FooterBox">
    <div className="ContentsBox clearfix">
      <ul className="share-tools">
        {obj.share && obj.share.length ? (
          obj.share.map(item => (
            // <li>
            //   <label id={`new${item.className}`} className={`amp-social-share-${item.className}`}><i className={`fab ${item.icon}`}></i><span>{item.className}</span></label>
            // </li>
            <li>
              <div
                style={{ width: '75px', height: '24px' }}
                href="http://line.naver.jp/R/msg/text/?大家跟我一起用Line分享吧!%0D%0Ahttp://blog.ja-anything.com/"
                target="_blank"
                className={`ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-${item.className} i-amphtml-built i-amphtml-layout`}
              >
                分享
              </div>
            </li>
          ))
        ) : (
          <>
            <li>
              <div
                style={{ width: '75px', height: '24px' }}
                className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-facebook i-amphtml-built i-amphtml-layout"
              >
                分享
              </div>
            </li>
            <li>
              <div
                style={{ width: '75px', height: '24px' }}
                className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-pinterest i-amphtml-built i-amphtml-layout"
              >
                分享
              </div>
            </li>
            <li>
              <div
                style={{ width: '75px', height: '24px' }}
                className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-twitter i-amphtml-built i-amphtml-layout"
              >
                分享
              </div>
            </li>
          </>
        )}
      </ul>
      <p id="newtitle13" className="copyrights">
        Copyrights All Rights Reserved.
      </p>
    </div>
  </footer>
</>

const contactObj = {
  href: '#contact', title: '關於我們', title2: '提供企業管理解決方案', titleImg: 'https://images.unsplash.com/photo-1441035573579-1825ff12fc2a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
  content: [
    // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
  ],
  child: [{
    href: '#contact1', title: '資宸科技', title2: '開創未來無限可能', titleImg: 'https://images.unsplash.com/photo-1506606401543-2e73709cebb4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
    content: [
      { layout: 'Section1', data: { title: '關於我們', content: '資宸科技創立於民國104年，專注在創新整合現有科技，為專業公司內部管理系統供應商，致力於開發真正符合使用者需求的軟硬體服務。我們有感於台灣不缺乏技術與人才，而是需要更好的創意思考，與更尊重人性的工作空間。因此資宸科技努立結合志同道合之士，共同打造一個重視專業能力，工作與生活平衡的一種新型態公司。我們重視每一位員工，提供良好工作環境，也提供學習及成長空間。' } },
      { layout: 'Section1', data: { title: '', content: '我們的經營理念是永續經營、精實創新，追求的是以最小的規模，創造客戶與使用者最大的價值。並以實質回饋給員工。誠信正直、產品創新、品質至上是我們對自己的要求。堅持創新、創造雙贏是我們對客戶的承諾。' } },
      { layout: 'Section1', data: { title: '', content: '我們的願景是利用軟硬整合的技術，專注在宗教與教育領域，解決目前使用者對於科技技術的障礙，致力於提升使用者的體驗，以簡單、自然，而兼顧美學的設計與操作邏輯，創造出易用的科技產品讓使用者將心力與專注回歸到真正對生活有價值的事物上。' } },
      { layout: 'Section1', data: { title: '合作夥伴', content: '名古屋愛知國際學院、台大嚴慶齡工業研究中心、桃園佛教蓮社、龍潭慈惠堂、台北龍雲寺、中華阿貲達顯密佛學會、漢栢略國際金融科技股份有限公司、柏德CITY社區、中原大學週邊套房出租、餐飲店家等。' } },
      // { layout: 'Section1', data: {title:'',content:''} },
    ],
  }, {
    //   href: '#contact2', title: '經銷合作', title2: '共同打造E化生活', titleImg: 'https://images.unsplash.com/photo-1491336477066-31156b5e4f35?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
    //   content: [
    //     { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
    //   ],
    // }, {
    href: '#contact2', title: '人才招募', title2: '與我們一起協助客戶、共同成長', titleImg: 'https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2698&q=80',
    content: [
      { layout: 'Section1', data: { title: '', content: '資宸科技成立至今，勇於迎接挑戰、尋求突破，匯聚各方菁英，投入可觀的資源規劃員工之訓練發展、營造良善的工作環境並建構多元福利制度，以提供員工無後顧之憂的照顧。我們打造人性化且舒適辦公環境，讓新進員工接受系統性的培訓課程，享有多元的學習發展資源，協助員工快速累積能真正服務到客戶的能力。' } },
      { layout: 'Section1', data: { title: '職缺查詢', content: '目前暫無職缺。' } },
    ],
  }, {
    href: '#contact3', title: '聯絡我們', title2: '產品疑問、企業合作', titleImg: 'https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
    content: [
      // { layout: 'Card1', data: {} },
      { layout: 'Section1', data: { title: '售後服務', content: '歡迎洽詢在地經銷商' } },
      // { layout: 'Section1', data: { title: '售後服務', content: '歡迎洽詢在地經銷商' } },
      { layout: 'Contact1', data: { bgImg: 'https://images.unsplash.com/photo-1469002372271-3406b43e1f27?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80' } },
    ],
  }]
};

const solutionObj = {
  href: '#solution', title: '解決方案', title2: '將科技實際帶入生活', titleImg: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
  content: [
    // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
  ],
  child: [{
    //   href: '#solution1', title: '雲端辦公系統', title2: '雇主隨時掌握公司狀況', titleImg: 'https://images.unsplash.com/photo-1498049860654-af1a5c566876?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
    //   content: [
    //     { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
    //   ],
    // }, {
    href: '#solution1', title: '教育機構', title2: '整體E化管理', titleImg: 'https://images.unsplash.com/photo-1606761568499-6d2451b23c66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2467&q=80',
    content: [
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '學校、語言學校', info: '', img: 'https://images.unsplash.com/photo-1519406596751-0a3ccc4937fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '補習班', info: '簽到、點名', img: 'https://images.unsplash.com/photo-1509062522246-3755977927d7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3204&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '安親班', info: '', img: 'https://images.unsplash.com/photo-1588072432686-b2bc721308cd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3152&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '幼兒園', info: '', img: 'https://images.unsplash.com/photo-1620398722262-969d8f2bc875?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '才藝班', info: '', img: 'https://images.unsplash.com/photo-1520523839897-bd0b52f945a0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80' } },
    ],
  }, {
    href: '#solution2', title: '套房經營', title2: '處理房東大小事', titleImg: 'https://images.unsplash.com/photo-1612769732688-b7d111799dca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2702&q=80',
    content: [
      {
        layout: 'Gallery', data: {
          title: '現場實例',
          arr: [
            { title: '門禁機安裝配線', x: '1108', y: '1478', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/54d8c344be222d92b05721f085cd8c22_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/1b76bb1d6cb718544e6358cad337fe6e_1.jpeg' },
            { title: '電控鎖串接門禁機', x: '1478', y: '1108', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/7daf1f42ef781c152d5ec2e1e81f18e6_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/6377ae121a0e23c4c4d0099f815b565a_1.jpeg' },
            { title: '門禁機配線', x: '1108', y: '1478', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/9f0a3c41c86ab51db5522912e2099601_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/444f9eac92bb909257c19e09809ae016_1.jpeg' },
            { title: '人臉門禁機安裝', x: '1108', y: '1478', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/22d156c55a6aca362871e393a126983c_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/b0c32d298195014fcc36edfb1d95a6b3_1.jpeg' },
            { title: '投幣式加熱區', x: '1280', y: '1280', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/eb8f562c9e817d4226f617d73cf5ee44_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/841fc8c5acc6e1f43aeba1aad49bd51b_1.jpeg' },
            { title: '投幣式洗衣機', x: '1108', y: '1478', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/4af74c524c091b8e17981c0171ceddf2_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/700e11aae1860e9490f957f74a9f7d3c_1.jpeg' },
            { title: '監視設備規劃', x: '1478', y: '1108', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/f3805f98c49dacbcab03258c93ea1055_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/948c70ff106c515641b7833b0786c7a3_1.jpeg' },
            { title: '監視設備佈線', x: '1108', y: '1478', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/e0bca750d36ad156fa5c0c319c965ef3_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/bebb8905a83577a22baf7fe97c73dce3_1.jpeg' },
            { title: '監視設備佈線', x: '1108', y: '1478', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/c37fe189dd1f2a0334ffbf17249800c5_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/3cf51efb30568c9f13f525bd65a7a52c_1.jpeg' },
            { title: '人體感應照明規劃', x: '1478', y: '1108', imgPre: 'https://parse3.infowin.com.tw/parse/files/parseServer/c2d8df8a7cc3cd7a622e040157e37465_2.jpeg', imgOri: 'https://parse3.infowin.com.tw/parse/files/parseServer/c5bdee77279868f9e42fcd20c62998ca_1.jpeg' },
            // { title: '', x: '', y: '', imgPre: '', imgOri: '' },
            // { title: '', x: '', y: '', imgPre: '', imgOri: '' },
            // { title: '', x: '', y: '', imgPre: '', imgOri: '' },
          ]
        }
      },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '催繳通知', info: '', img: 'https://images.unsplash.com/photo-1556741568-055d848f8bfd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '水電報修', info: '', img: 'https://images.unsplash.com/photo-1517646287270-a5a9ca602e5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2700&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '電子合約', info: '', img: 'https://images.unsplash.com/photo-1562564055-71e051d33c19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2700&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '收支總覽', info: '', img: 'https://images.unsplash.com/photo-1591696205602-2f950c417cb9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '自動設備', info: '', img: 'https://images.unsplash.com/photo-1484154218962-a197022b5858?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2706&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '節電控制', info: '', img: 'https://images.unsplash.com/photo-1505410807948-88c1d8ac0e4a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
    ],
  }, {
    href: '#solution3', title: '資產管理', title2: '協助客戶投資保單、不動產、股票、基金', titleImg: 'https://images.unsplash.com/photo-1579621970795-87facc2f976d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2250&q=80',
    content: [
      { layout: 'Blog1', data: { title1Color: '', title1: '功能', title2: '每月結算報表', info: '', img: 'https://images.unsplash.com/photo-1591696205602-2f950c417cb9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2250&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '功能', title2: '投資標的查閱', info: '', img: 'https://images.unsplash.com/photo-1609902726285-00668009f004?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80' } },
      // { layout: 'Blog1', data: { title1Color: '', title1: '系統功能', title2: '投資標的查閱', info: '', img: 'https://images.unsplash.com/photo-1612178991541-b48cc8e92a4d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80' } },
      // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '保單', info: '', img: 'https://images.unsplash.com/photo-1558980971-97f50d0fed00?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2192&q=80' } },
      // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '不動產', info: '', img: 'https://images.unsplash.com/photo-1600168488491-03d6c3486ab5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80' } },
      // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '股票', info: '', img: 'https://images.unsplash.com/photo-1615753378567-f9d071cd1375?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80' } },
      // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '基金', info: '', img: 'https://images.unsplash.com/photo-1615753378567-f9d071cd1375?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80' } },
    ],
  }, {
    href: '#solution4', title: '個體預約', title2: '美甲、做臉、SPA、造型、新秘、主持', titleImg: 'https://images.unsplash.com/photo-1485178575877-1a13bf489dfe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2191&q=80',
    content: [
      // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '課程介紹', info: '', img: 'https://images.unsplash.com/photo-1600334089648-b0d9d3028eb2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '產品介紹', info: '', img: 'https://images.unsplash.com/photo-1515377905703-c4788e51af15?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '線上預約', info: '', img: 'https://images.unsplash.com/photo-1435527173128-983b87201f4d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2247&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '員工抽佣', info: '', img: 'https://images.unsplash.com/photo-1498568715259-5c1dc96aa8e7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80' } },
      // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '產品費用', info: '', img: 'https://images.unsplash.com/photo-1615753378567-f9d071cd1375?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80' } },
    ],
  }, {
    href: '#solution5', title: '寺廟法會', title2: '辦理信眾消災祈福', titleImg: 'https://images.unsplash.com/photo-1530688957198-8570b1819eeb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3447&q=80',
    content: [
      // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '佛教', info: '', img: 'https://images.unsplash.com/photo-1558980971-97f50d0fed00?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2192&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '道教', info: '', img: 'https://images.unsplash.com/photo-1600168488491-03d6c3486ab5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80' } },
      { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '藏傳佛教', info: '', img: 'https://images.unsplash.com/photo-1615753378567-f9d071cd1375?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80' } }
    ],
  },]
}

const commonArr = [{
  nomenu: true,
  href: '#privacy', title: '隱私權政策', title2: '資宸科技非常重視您的隱私權', titleImg: 'https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2700&q=80',
  content: [
    // { layout: 'Blog1', data: { title1Color: '', title1: '個資法', title2: '', info: '', img: 'https://images.unsplash.com/photo-1593626241485-528432af22c2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
    {
      layout: 'Blog1', data: {
        title1Color: '', title1: '資料安全', title2: '', info: `本網站群與第三者共用個人資料之政策
    本網站群絕對不會任意出售、交換、出租或以其他變相之方式，將使用者的個人資料揭露與其他團體或個人。惟有下列三種情形，本網站群會與第三人共用使用者的個人資料：
    o 經過使用者的事前同意或授權允許時。
    o 司法單位或其他主管機關經合法正式的程序要求時。
    o 為了提供使用者其他服務或優惠權益，需要與提供該服務或優惠之第三人共用使用者的資料時，本網站會在活動時提供充分說明並告知，使用者可以自由選擇是否接受這項服務或優惠。`, img: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2691&q=80'
      }
    },
    {
      layout: 'Blog1', data: {
        title1Color: '', title1: '隱私權', title2: ``, info: `隱私權 本網站群自動收集之資訊
        當使用者瀏覽或查詢使用本網站群時，本網站群可能自動記錄的資訊包括：使用者的作業系統、網際網路通訊協定(IP)位址、使用設備資訊、使用時間、瀏覽器類型與語言以及點選資料紀錄等，以及使用者在瀏覽本公司網站之前所瀏覽的網站。 本網站群可能使用網路信標、Cookies、本機共用物件(LSO)及類似方法(定義如下)來記錄資訊。若使用者不希望本網站群使用Cookies，則除非使用者先依照下面說明的方式停用Cookies，否則請不要使用本網站群服務。本網站群得自動將所收集到的任何資訊連結至個人資訊。
        o 網路信標
        網路信標係指本網站群可能在網站上或電子郵件中使用的電子影像，其目的是用來傳送Cookies、計算瀏覽次數，並了解使用情況與活動成效。
        o Cookies
        Cookies係指網站儲存在使用者硬碟上的小型資料檔案。本網站群將使用Cookies以協助改進網站使用經驗，通常透過瀏覽器設定即可移除並拒絕從網站傳送的Cookies。`, img: 'https://images.unsplash.com/photo-1580847097346-72d80f164702?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2702&q=80'
      }
    },
    // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
  ],
}, {
  nomenu: true,
  href: '#404', title: '404', title2: '找不到頁面', titleImg: 'https://images.unsplash.com/photo-1473798349978-d18a8c8dbf41?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80',
  content: [
    // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
  ],
}]

export const allSiteObj = {
  'F2BByjzuro': {
    // home: {},
    menuSetting: {
      bgColor: 'info',
      middleBgColor: 'info'
    },
    footer: {},
    menuArr: [{
      nomenu: true,
      href: '.', title: '資宸科技有限公司', title2: '致力推動企業e化解決方案', titleImg: 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2702&q=80',
      content: [
        // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
      ],
    }, ...commonArr, {
      href: '#door', title: '門禁管理', title2: '機電到雲端 一站整合', titleImg: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2495&q=80',
      content: [
        // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
      ],
      child: [{
        href: '#door1', title: '人臉識別感溫機', title2: '即時掌握出入人員狀況', titleImg: 'https://images.unsplash.com/photo-1517077304055-6e89abbf09b0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
        content: [
          { layout: 'Feature1', data: { img: 'https://parse3.infowin.com.tw/parse/files/myappID/bf23854e0f0a60400e8748ce314bb3c8_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '人臉識別感溫門禁機 可戴口罩、感溫功能，無需自行架設伺服器接收資料。', info: '有各種尺寸，適用於各種場景。', img: 'https://parse3.infowin.com.tw/parse/files/myappID/66deb9d373481785ec9dd1680b2eec20_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '設備', title2: '感應閘門，可搭配人臉識別感溫機使用', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/1c753e06b761eca5b9de38e1820aaa16_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '人臉門禁機 不能戴口罩、無感溫功能，需自行架設伺服器接收資料。', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/39c460f871ecf8e743d209a74eb58418_1.jpeg' } },
        ],
      }, {
        href: '#door2', title: '自動門弓器', title2: '科技提升居家安全', titleImg: 'https://images.unsplash.com/photo-1537151377170-9c19a791bbea?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
        content: [
          // { layout: 'Blog1', data: {title1:'', title2:'',info:'',img:'' } },
          { layout: 'Blog1', data: { title1Color: 'warning', title1: '機器', title2: '自動門弓器', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/1c67cc9a267061a0d238d28c600f9fa5_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: 'warning', title1: '機器', title2: '自動門弓器 門弓規格', info: '', img: 'https://parse3.infowin.com.tw/parse/files/parseServer/2106c588541a1c2160589f0bfcbcde1b_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '電磁鎖', title2: '吸力700公斤', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/fd0efd1d63d31be9849eb65a09b04255_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '門禁機人體感應開關自動門防夾開關，紅外線感應，自動開關。', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/f1922e039272a923cfc7f206c943cc2b_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '門禁機人體感應開關自動門防夾開關，規格。', info: '', img: 'https://parse3.infowin.com.tw/parse/files/parseServer/22bea5746c7812fbe6c328b62bd6b6bd_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '門禁機人體感應開關微波感應，可控制感應範圍、距離。', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/412c39e2f4f43ba27396af43aa97c956_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '門禁機人體感應開關微波感應，可控制感應範圍、距離。', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/14236d58f3db7758701920f845f151c9_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '門禁開關。紅外線感應，自動開關。', info: '', img: 'https://parse3.infowin.com.tw/parse/files/parseServer/2d9d73f63d5356950261a05f6662a47c_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '門禁開關。鋁合金製造，防鏽耐用。', info: '不鏽鋼拉絲，美觀大方', img: 'https://parse3.infowin.com.tw/parse/files/myappID/59897326635a230011f766966b19af1e_1.jpeg' } },
        ],
      }, {
        href: '#door3', title: '電子鎖', title2: '沒帶磁卡也可按密碼開門', titleImg: 'https://images.unsplash.com/photo-1574679114178-94beba6d92b4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
        content: [
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '房門電子鎖 IC卡，無WIFI，需鑽孔，無需佈線。', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/e0aed895b458c0231cd6983d2dba583a_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '電控鎖。串接感應裝置，實現遠端開門', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/a9646c26b2fea187c81e34f385db0bd8_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '電控鎖。串接感應裝置，實現遠端開門', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/dd8a6f34c770a132e0bc29a227ab0a79_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: 'IC/ID感應開門機，WIFI可開，需佈線。', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/428bb9f6f63434e8da8419fd69424c02_1.jpeg' } },
          // { layout: 'Blog1', data: { title1Color: '', title1: 'LED人體感應開關', title2: '', info: '', img: '' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '設備', title2: '人體感應燈控制器。適用於走道控制LED之紅外線感應器。天亮時不做動，晚上時人體經過自動開關。控制電壓：110V~220V', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/896062d242b8af5660c8dc2a50014189_1.jpeg' } },
        ],
      }, {
        href: '#door4', title: '監控設備', title2: '保護好您珍貴的資產', titleImg: 'https://images.unsplash.com/photo-1512790182412-b19e6d62bc39?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2689&q=80',
        content: [
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '室外監控設備', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/fdb85efbed6d1bf389a758fcac4f7b51_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '室內監控設備', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/27e35a0f4d4a5fa3f6dced446c2a685b_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '耗材', title2: '室內環景監控設備', info: '360度無死角監控', img: 'https://parse3.infowin.com.tw/parse/files/myappID/bc4692d784d9fb31fd1846c753f2ef59_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '耗材', title2: '監控錄影主機', info: '硬碟式，16路，可存約一個禮拜', img: 'https://parse3.infowin.com.tw/parse/files/myappID/d539f61fd5cffc0fdb99d7e6ded168a0_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '耗材', title2: '監控電纜', info: '網路線或傳統攝影機接頭', img: 'https://parse3.infowin.com.tw/parse/files/myappID/cae4fab718128ccf769fda4bf2c39723_1.jpeg' } },
        ],
      }, {
        href: '#door5', title: '門禁卡', title2: '識別證卡、汽車機車感應貼紙', titleImg: 'https://images.unsplash.com/photo-1610303147219-e0e97f883454?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
        content: [
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '汽機車感應RFID器', info: 'Long range integrated uhf rfid reader, uhf rfid reader Frequency 902-928 MHz (EU standard) or 865-868mhz (EU standard)', img: 'https://parse3.infowin.com.tw/parse/files/myappID/41249570a17a3cf39567427b063c0e7c_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '耗材', title2: '汽機車感應RFID貼紙', info: 'Chip: Impinj Monza 4 Die Cut Size: 44mm*44mm Antenna Size: Apllication test result: Fashion, Rag trade Retail Assest management Logistic', img: 'https://parse3.infowin.com.tw/parse/files/myappID/8ca0b7d437715e37862708a951cc66b0_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '耗材', title2: '白卡 IC/ID/RFID/NFC', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/f3b9745aca7538fcf1949380f53f1996_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '耗材', title2: '磁扣 IC/ID/RFID/NFC', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/c638cff69654b65b32566af9bee346c4_1.jpeg' } },
        ],
      }]
    }, {
      href: '#pos', title: 'POS系統', title2: '輕鬆處理各式銷售訂單', titleImg: 'https://images.unsplash.com/photo-1556742044-3c52d6e88c62?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
      content: [
        // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
      ],
      child: [{
        href: '#pos1', title: 'POS機', title2: '適用於不同環境', titleImg: 'https://images.unsplash.com/photo-1509017174183-0b7e0278f1ec?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2702&q=80',
        content: [
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '前台點餐POS機', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/0ed0596e84fa02e6fa156084c431b058_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '手持式點餐發票機', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/8d7872045b41c2874a885f91e111bd85_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '手持式點餐發票機', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/d26c8d83f531890310e7e9c897e2c013_1.jpeg' } },
          // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '錢箱', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/110e5b07f432b337d37489911696130b_1.jpeg' } },
        ],
      },
      // { href: '#pos2', title: '手持式POS機', title2: '', titleImg: '' },
      // { href: '#pos3', title: '手持式發票機', title2: '', titleImg: '' },
      // 熱感發票紙
      {
        href: '#pos2', title: '熱感發票機', title2: '解決各種發票報稅事務', titleImg: 'https://images.unsplash.com/photo-1605978542268-b49288706bfd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
        content: [
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '熱感發票機 規格：58mm，80mm', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/15ad1e735c20c02b69f5febc5c2cdb86_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '耗材', title2: '熱感發票紙', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/524913df93e926709b2c21908eff79f1_1.jpeg' } },
          // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
        ],
      }, {
        href: '#pos3', title: '點陣式印表機', title2: '大量列印經濟快速', titleImg: 'https://images.unsplash.com/photo-1553605357-97d8370fc8c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2250&q=80',
        content: [
          { layout: 'Blog1', data: { title1Color: '', title1: '機器', title2: '點陣式印表機', info: 'A4大小，字體清晰、速度快、聲音小', img: 'https://parse3.infowin.com.tw/parse/files/myappID/e69b5bb00bc52e1c9caec036ad39b4f3_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '耗材', title2: '點陣式印表機 專用紙', info: 'A4大小。一式兩份、三份、四份。一頁兩折、三折、四折', img: 'https://parse3.infowin.com.tw/parse/files/myappID/e25267d14ac0b27f075bbd3523678919_1.jpeg' } },
        ],
      }, {
        href: '#pos4', title: '藍芽條碼機', title2: '一維條碼、二維QR CODE', titleImg: 'https://images.unsplash.com/photo-1595079835357-a94a13cab10c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
        content: [
          { layout: 'Blog1', data: { title1Color: '', title1: '設備', title2: '一維條碼、二維QR CODE', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/d7b4e42ccd55f2353680fb04fa5c1fd6_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
        ],
      }, {
        href: '#pos5', title: '投幣計時器', title2: '協助您提供更多服務', titleImg: 'https://images.unsplash.com/photo-1595780784502-64b04f614781?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2167&q=80',
        content: [
          { layout: 'Blog1', data: { title1Color: '', title1: '設備', title2: '投幣控制箱', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/214d18ee243cf6e98c843f32a6ba3ed6_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '投幣器', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/9a9839180510075d50736faf302a9b94_1.jpeg' } },
          { layout: 'Blog1', data: { title1Color: '', title1: '配件', title2: '計時器', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/af3eff4d7b97032bc49fe866cd361406_1.jpeg' } },
        ],
      }]
    }, {
      href: '#office', title: '整合開發', title2: '軟硬體協同開發團隊', titleImg: 'https://images.unsplash.com/photo-1499346030926-9a72daac6c63?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
      content: [
        // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
      ],
      child: [
        // { href: '#office1', title: '訂單管理', title2: '', titleImg: '' },
        // { href: '#office1', title: '客戶維護', title2: '', titleImg: 'https://images.unsplash.com/photo-1612550761236-e813928f7271?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' },
        // { href: '#office2', title: '各類報表', title2: '', titleImg: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2426&q=80' },
        {
          href: '#office1', title: '客製雲端系統', title2: '優化公司作業流程', titleImg: 'https://images.unsplash.com/photo-1612550761236-e813928f7271?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
          content: [
            { layout: 'Blog1', data: { title1Color: '', title1: '開發', title2: '開發客製化系統', info: '公司內部多人雲端管理系統', img: 'https://parse3.infowin.com.tw/parse/files/myappID/55267af74c356bf5992c04ad69f7d0a7_1.jpeg' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '流程', title2: '提案、報價、簽約、頭期款、製作、校訂、結案', info: '過程公開、價格合理，依工期收費', img: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '功能調整', title2: '有新的想法嗎？', info: '與提案內容不同，需要更改的部份，若尚未製作，可提出新的製作內容。', img: 'https://images.unsplash.com/photo-1552664730-d307ca884978?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '保固', title2: '原測試沒問題之功能，保固一個月', info: '有些軟體公司或外包接案人員，在結案後即對產品不再保固。', img: 'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '咨詢', title2: '不確定該怎麼做嗎？', info: '本公司免費與您討論功能設計、排版，並提出適當建議供您參考。', img: 'https://images.unsplash.com/photo-1606836591695-4d58a73eba1e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80' } },
          ],
        }, {
          href: '#office2', title: 'PDF列印', title2: '獨家線上生成中文PDF技術', titleImg: 'https://images.unsplash.com/photo-1521791055366-0d553872125f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
          content: [
            { layout: 'Blog1', data: { title1Color: '', title1: '列印', title2: '客製化列印內容', info: '只要您想得到，都可以直接產出PDF', img: 'https://parse3.infowin.com.tw/parse/files/myappID/55520947ca8b6b105fb2260b7388e408_1.jpeg' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '自訂', title2: '各式報表、文字', info: '收據、報價單、名冊等，自訂您要的內容', img: 'https://images.unsplash.com/photo-1520004434532-668416a08753?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '自訂', title2: '各式圖表', info: '統計圖、圓餅圖、折線圖、雷達圖等，可依不同需求即時印出您要的內容', img: 'https://images.unsplash.com/photo-1543286386-713bdd548da4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80' } },
          ],
        }, {
          href: '#office3', title: '人臉辦識機', title2: '雲端即時掌握各項出入資料', titleImg: 'https://images.unsplash.com/photo-1534224039826-c7a0eda0e6b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2700&q=80',
          content: [
            { layout: 'Blog1', data: { title1Color: '', title1: '開發', title2: '開發人臉辨識各項功能', info: '', img: 'https://images.unsplash.com/photo-1583109193439-1ebb113bceac?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1874&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
          ],
        }, {
          href: '#office4', title: 'LINE串接', title2: '自訂各種應用、LINE金流', titleImg: 'https://images.unsplash.com/photo-1480694313141-fce5e697ee25?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
          content: [
            // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '開發', title2: 'LINE機器人 開發', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/ef17e27e25e97a0110addd5b2fe74357_1.jpeg' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '開發', title2: 'LINE pay金流 開發', info: '', img: 'https://parse3.infowin.com.tw/parse/files/myappID/bcfd32b797792a7962801de1b76edc15_1.jpeg' } },
          ],
        },]
    }, solutionObj, contactObj
    ]
  },
  'lu6226DYO8': {
    // home: {},
    menuSetting: {
      bgColor: 'primary',
      middleBgColor: 'primary'
    },
    footer: {},
    menuArr: [{
      nomenu: true,
      href: '.', title: '雲端辦公系統', title2: '解決企業的管理需求', titleImg: 'https://images.unsplash.com/photo-1499346030926-9a72daac6c63?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
      content: [
        // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
      ],
      child: []
    }, ...commonArr, {
      href: '#iface', title: 'iFace後台', title2: '', titleImg: 'https://images.unsplash.com/photo-1545665277-5937489579f2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
      content: [
        // { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
      ],
      child: [ // 開門記錄
        {
          href: '#iface1', title: '後台功能', title2: '', titleImg: 'https://images.unsplash.com/photo-1542744173-05336fcc7ad4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2791&q=80',
          content: [
            { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '即控監控', info: '', img: 'https://images.unsplash.com/photo-1583607327197-af9fbdd111d9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '統計報表', info: '', img: 'https://images.unsplash.com/flagged/photo-1553267252-d100936057c1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '遠端管理', info: '', img: 'https://images.unsplash.com/photo-1579719558505-ad4a5fee0847?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3436&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '', title2: 'LINE串接', info: '', img: 'https://images.unsplash.com/photo-1500021804447-2ca2eaaaabeb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
          ],
        }, {
          href: '#iface2', title: '應用場景', title2: '', titleImg: 'https://images.unsplash.com/photo-1542404937-2132aa1fa6fc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
          content: [
            { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '校務系統', info: '', img: 'https://images.unsplash.com/photo-1585637071663-799845ad5212?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '父母查看', info: '', img: 'https://images.unsplash.com/photo-1560541919-eb5c2da6a5a3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80' } },
            { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '學生系統', info: '', img: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2700&q=80' } },
          ],
          // }, {
          // href: '#iface3', title: '價格', title2: '', titleImg: '',
          // content: [
          //   { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '價格一覽', info: '', img: '' } },
          //   { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '服務內容', info: '', img: '' } },
          // ],
          // }, {
          //   href: '#iface4', title: '', title2: '', titleImg: '',
          //   content: [
          //     { layout: 'Blog1', data: { title1Color: '', title1: '', title2: '', info: '', img: '' } },
          //   ],
        }]
    }, solutionObj, {
      href: '#price', title: '價格', title2: '操作容易 價格合理', titleImg: 'https://images.unsplash.com/photo-1563013544-824ae1b704d3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
      content: [
        { layout: 'Blog1', data: { title1Color: '最新消息', title1: '服務內容', title2: '搭配IFACE機型，免費開通雲辦公後台。', info: '', img: 'https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2700&q=80' } },
      ],
    }, contactObj
    ]
  }
}


