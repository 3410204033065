

import React from 'react';
// import AuthLayout from 'components/auth/AuthLayout/AuthLayout';
import Error404 from './Error404';
import FrontLayout from 'components/FrontLayout/FrontLayout';

const title = 'Error 404';

function action(props) {
  // console.log(props)
  return {
    chunks: ['error404'],
    title,
    component: (
      <FrontLayout>
        <Error404 title={title} {...props} />
      </FrontLayout>
    ),
  };
}

export default action;
