import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Container,
  Col,
  Row
} from 'reactstrap';
import ColorNavbar from "components/client/ClientNavbar/ColorNavbarTest.js";
import FooterGray from 'components/Footers/FooterGray.js';

import { allSiteObj } from 'routes/official/CustomMenu.js';

class Error404 extends React.Component {
  // componentDidMount() {
  //   document.body.classList.toggle('error-404');
  // }

  // componentWillUnmount() {
  //   document.body.classList.toggle('error-404');
  // }

  render() {
    const { companyObj } = this.props
    // const { isOpen, obj, image, loading, } = this.state;

    const menuArr = (allSiteObj[companyObj.objectId] && allSiteObj[companyObj.objectId].menuArr) || [];
    const menuSetting = (allSiteObj[companyObj.objectId] && allSiteObj[companyObj.objectId].menuSetting) || [];
    // console.log(menuArr)

    return (<div className="error-404">
      <ColorNavbar {...this.props} menuArr={menuArr} menuSetting={menuSetting} />

      <Container>
        <Row>
          <h1 className="title">
            404 錯誤
            <br />
            <p className="error-msg">
              找不到這個網頁
            </p>
          </h1>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${'https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2700&q=80'})`,
        }}
      />
      <FooterGray />
    </div>);
  }
}

export default Error404;
